require("./main.css");
const reveal = require("reveal.js");

reveal.initialize({
  width: "100%",
  height: "100%",
  margin: 0.2,
  minScale: 1,
  maxScale: 1,

  controls: true,
  progress: true,
  history: true,
  center: true,
  touch: true,
  hideAddressBar: true,
  backgroundTransition: "fade",

  transition: "fade",
});
